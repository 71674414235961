/**
 * Created by gschulz on 11.04.16.
 */
let EfbHelperTimeField = (function () {

    let _private = {};

    this.numericKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    this.specialKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

    this.initTimeStampFields = (fields, blurCallBack = function () {}) => {
        fields.unbind('keypress.initTimeStampFields').on('keypress.initTimeStampFields', event => {
                if (_private.getSelectionText() !== '') {
                    return;
                }
                _private.completeTimeStampFieldAdd(event);
            }
        ).unbind('focus.initTimeFields').on('focus.initTimeFields', event => {
                $(event.target).select();
            }
        ).unbind('blur.initTimeStampFields').on('blur.initTimeStampFields', event => {
            let res = _private.completeTimeStampFieldBlur(event);
            blurCallBack.apply(this, [event, res]);
        });
    };

    this.initTimeFields = (fields, blurCallBack = function () {}) => {
        fields.unbind('keypress.initTimeFields').on('keypress.initTimeFields', event => {
                if (_private.getSelectionText() !== '') {
                    return;
                }
                _private.completeTimeFieldAdd(event);
            }
        ).unbind('focus.initTimeFields').on('focus.initTimeFields', event => {
                let field = $(event.target);
                if (field.prop('readonly') || field.prop('disabled')) {
                    return;
                }
                field.select();
            }
        ).unbind('blur.initTimeFields').on('blur.initTimeFields', event => {
            let res = _private.completeTimeFieldBlur(event);
            blurCallBack.apply(this, [event, res]);
        });
    };

    this.getTimeStampString = function (date, addSeconds) {
        if (_.isNumber(date)) {
            date = new Date(date * 1000);
        }
        let hours   = $.sprintf('%02d', date.getHours());
        let minutes = $.sprintf('%02d', date.getMinutes());
        hours       = $.sprintf('%02d', hours);
        let result  = hours + ':' + minutes;
        if (addSeconds) {
            result += ':' + $.sprintf('%02d', Math.floor(date.getSeconds()));
        }
        return result;
    };

    this.getTimeString = function (date, addSeconds) {
        if (_.isNumber(date)) {
            date = new Date(date * 1000);
        }
        let hours   = Math.floor(date.getTime() / 3600000);
        let minutes = $.sprintf('%02d', date.getMinutes());
        let result  = hours + 'h' + minutes;
        if (addSeconds) {
            result += ':' + $.sprintf('%02d', Math.floor(date.getSeconds()));
        }
        return result;
    };


    _private.completeTimeStampFieldAdd = event => {
        if ($.inArray(event.key, this.specialKeys) !== -1) {
            return;
        }
        let allowedKeys  = $.extend(true, [], this.numericKeys);
        let element      = $(event.target);
        let value        = element.val();
        let hasSeparator = value.indexOf(':') !== -1;
        if (!hasSeparator) {
            allowedKeys.push(':');
        }
        if ($.inArray(event.charCode, allowedKeys) === -1) {
            event.preventDefault();
            return;
        }
        if (!hasSeparator && value.length === 1 && event.charCode !== 58) {
            element.val(value.substr(0, 1) + ':' + value.substr(1, 2));
        }
        if (/^(([0-9]):([0-9][0-9]))$/.test(value)) {
            element.val(value.substr(0, 1) + value.substr(2, 1) + ':' + value.substr(3, 1));
        }
    };


    _private.completeTimeFieldAdd = event => {
        if ($.inArray(event.key, this.specialKeys) !== -1) {
            return;
        }
        let allowedKeys  = $.extend(true, [], this.numericKeys);
        let element      = $(event.target);
        let value        = element.val();
        let parts        = value.split('h');
        let hasSeparator = parts.length === 2;
        let caret        = element.caret();
        let enterMin     = hasSeparator && caret >= parts[0].length + 1;
        if (!hasSeparator) {
            allowedKeys.push('h');
        }
        if ($.inArray(event.key, allowedKeys) === -1 || enterMin && parts[1].length > 1) {
            event.preventDefault();
            return;
        }
        if (!hasSeparator && value.length === 2 && event.charCode !== 72) {
            element.val(value.substr(0, 1) + 'h' + value.substr(1, 2));
        } else if (/^((\d)h(\d{2}))$/.test(value)) {
            element.val(parts[0] + 'h' + parts[1]);
        }
    };

    _private.completeTimeStampFieldBlur = event => {
        let element = $(event.target);
        let value   = element.val();
        if($.trim(value) === ''){
            if(parseInt(element.attr('validate-required')) === 1){
                element.val('00:00');
            }
            return;
        }
        if (/^([0-9])$/.test(value)) {
            element.val('00:0' + value);
        } else if (/^([0-5][0-9])$/.test(value)) {
            element.val('00:' + value);
        } else if (/^(([0-9]):([0-5][0-9]))$/.test(value)) {
            element.val('0' + value);
        }
        if (!this.validateTimeStamp(element)) {
            return false;
        }
        let seconds = this.getTimeFieldSeconds(element, ':');
        Validator.validateElement(element);
        _private.setPureValue(element, seconds);
        return seconds;
    };

    _private.getSelectionText = () => {
        let text = '';
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== 'Control') {
            text = document.selection.createRange().text;
        }
        return text;
    };

    _private.setPureValue = (element, pureValue) => {
        if (element && element.get(0) && element.get(0).hasAttribute('pure-value')) {
            element.attr('pure-value', pureValue);
        }
    };

    _private.completeTimeFieldBlur = event => {
        let element = $(event.target);
        let value   = element.val();
        if($.trim(value) === ''){
            if(parseInt(element.attr('validate-required')) === 1){
                element.val('0h00');
            }
            return null;
        }
        let parts   = value.split('h');
        if (/^([0-9])$/.test(value)) {
            element.val('0h0' + value);
        } else if (/^([0-5][0-9])$/.test(value)) {
            element.val('0h' + value);
        } else if (/^(([0-9]*)h([0-9]))?$/.test(value)) {
            element.val(parts[0] + 'h0' + parts[1]);
        } else if (parts[1] && parseInt(parts[1]) > 59) {
            let min = parseInt(parts[0]) * 60 + parseInt(parts[1]);
            element.val(this.secondToString(min * 60, 'h'));
            return min * 60;
        }
        if (!this.validateTime(element)) {
            return false;
        }
        let seconds = this.getTimeFieldSeconds(element, 'h');
        _private.setPureValue(element, seconds);
        element.val(this.secondToString(seconds, 'h'));
        return seconds;
    };

    this.getTimeFieldSeconds = (element, separator, fail) => {
        fail      = !_.isUndefined(fail) ? fail : 0;
        let value = typeof element === 'string' ? element : $(element).val();
        let regex = new RegExp('^(\\d+)' + separator + '(\\d{2})$');
        if (!regex.test('' + value)) {
            return fail;
        }
        let valueParts = value.split(separator);
        return parseInt(valueParts[0]) * 3600 + parseInt(valueParts[1]) * 60;
    };


    this.setTimeFieldSeconds = (element, seconds, separator) => {
        if (seconds === null) {
            element.val('');
            return;
        }
        if (typeof seconds !== 'number' || _.isNaN(seconds)) {
            Validator.validateElement(element);
            return;
        }
        _private.setPureValue(element, seconds);
        element.val(this.secondToString(seconds, separator));
        Validator.validateElement(element);
    };

    this.secondToString = (seconds, separator) => {
        separator   = $.inArray(separator, [':', 'h']) === -1 ? ':' : separator;
        let hours   = Math.floor(seconds / 3600);
        let minutes = new StringHelper().sprintf('%02d', Math.floor(seconds / 60 % 60));
        if (separator === ':') {
            hours = new StringHelper().sprintf('%02d', hours % 24);
        }
        return hours + separator + minutes;
    };


    /**
     * Validate time-format
     *
     * @param {jQuery|string} element
     * @returns {Boolean}
     */
    this.validateTimeStamp = element => {
        return _private.validate(element, /^((([0-1]*[0-9])|([2][0-3])):([0-5][0-9]))?$/i);
    };

    /**
     * Validate time-format
     *
     * @param {jQuery|string} element
     * @returns {Boolean}
     */
    this.validateTime = element => {
        return _private.validate(element, /^(([0-9]*)h([0-5][0-9]))?$/i);
    };

    _private.validate = (element, regexp) => {
        let timeString = element;
        if (typeof element !== 'string') {
            timeString = $(element).val();
        }
        if (typeof timeString !== 'string') {
            return false;
        }
        let result = regexp.test(timeString);
        if (typeof element !== 'string') {
            Validator.removeError(element, Validator.NsInvalidTimeFormat);
            if (!result) {
                Validator.addError(element, Validator.NsInvalidTimeFormat);
            }
        }
        return result;
    };

    return this;
});